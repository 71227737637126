import { defineComponent } from 'vue';
import PropTypes from '../_util/vue-types';
import initDefaultProps from '../_util/props-util/initDefaultProps';
import StatisticNumber from './Number';
import Skeleton from '../skeleton/Skeleton';
import useConfigInject from '../_util/hooks/useConfigInject';
export const statisticProps = () => ({
    prefixCls: String,
    decimalSeparator: String,
    groupSeparator: String,
    format: String,
    value: {
        type: [String, Number, Object],
    },
    valueStyle: { type: Object, default: undefined },
    valueRender: PropTypes.any,
    formatter: PropTypes.any,
    precision: Number,
    prefix: PropTypes.any,
    suffix: PropTypes.any,
    title: PropTypes.any,
    loading: { type: Boolean, default: undefined },
});
export default defineComponent({
    name: 'AStatistic',
    props: initDefaultProps(statisticProps(), {
        decimalSeparator: '.',
        groupSeparator: ',',
        loading: false,
    }),
    slots: ['title', 'prefix', 'suffix', 'formatter'],
    setup(props, { slots }) {
        const { prefixCls, direction } = useConfigInject('statistic', props);
        return () => {
            var _a, _b, _c, _d, _e, _f, _g;
            const { value = 0, valueStyle, valueRender } = props;
            const pre = prefixCls.value;
            const title = (_a = props.title) !== null && _a !== void 0 ? _a : (_b = slots.title) === null || _b === void 0 ? void 0 : _b.call(slots);
            const prefix = (_c = props.prefix) !== null && _c !== void 0 ? _c : (_d = slots.prefix) === null || _d === void 0 ? void 0 : _d.call(slots);
            const suffix = (_e = props.suffix) !== null && _e !== void 0 ? _e : (_f = slots.suffix) === null || _f === void 0 ? void 0 : _f.call(slots);
            const formatter = (_g = props.formatter) !== null && _g !== void 0 ? _g : slots.formatter;
            // data-for-update just for update component
            // https://github.com/vueComponent/ant-design-vue/pull/3170
            let valueNode = (<StatisticNumber data-for-update={Date.now()} {...Object.assign(Object.assign({}, props), { prefixCls: pre, value, formatter })}/>);
            if (valueRender) {
                valueNode = valueRender(valueNode);
            }
            return (<div class={[pre, { [`${pre}-rtl`]: direction.value === 'rtl' }]}>
          {title && <div class={`${pre}-title`}>{title}</div>}
          <Skeleton paragraph={false} loading={props.loading}>
            <div style={valueStyle} class={`${pre}-content`}>
              {prefix && <span class={`${pre}-content-prefix`}>{prefix}</span>}
              {valueNode}
              {suffix && <span class={`${pre}-content-suffix`}>{suffix}</span>}
            </div>
          </Skeleton>
        </div>);
        };
    },
});
