import Notification from '../vc-notification';
import LoadingOutlined from '@ant-design/icons-vue/LoadingOutlined';
import ExclamationCircleFilled from '@ant-design/icons-vue/ExclamationCircleFilled';
import CloseCircleFilled from '@ant-design/icons-vue/CloseCircleFilled';
import CheckCircleFilled from '@ant-design/icons-vue/CheckCircleFilled';
import InfoCircleFilled from '@ant-design/icons-vue/InfoCircleFilled';
import classNames from '../_util/classNames';
let defaultDuration = 3;
let defaultTop;
let messageInstance;
let key = 1;
let localPrefixCls = '';
let transitionName = 'move-up';
let hasTransitionName = false;
let getContainer = () => document.body;
let maxCount;
let rtl = false;
export function getKeyThenIncreaseKey() {
    return key++;
}
function setMessageConfig(options) {
    if (options.top !== undefined) {
        defaultTop = options.top;
        messageInstance = null; // delete messageInstance for new defaultTop
    }
    if (options.duration !== undefined) {
        defaultDuration = options.duration;
    }
    if (options.prefixCls !== undefined) {
        localPrefixCls = options.prefixCls;
    }
    if (options.getContainer !== undefined) {
        getContainer = options.getContainer;
        messageInstance = null; // delete messageInstance for new getContainer
    }
    if (options.transitionName !== undefined) {
        transitionName = options.transitionName;
        messageInstance = null; // delete messageInstance for new transitionName
        hasTransitionName = true;
    }
    if (options.maxCount !== undefined) {
        maxCount = options.maxCount;
        messageInstance = null;
    }
    if (options.rtl !== undefined) {
        rtl = options.rtl;
    }
}
function getMessageInstance(args, callback) {
    if (messageInstance) {
        callback(messageInstance);
        return;
    }
    Notification.newInstance({
        appContext: args.appContext,
        prefixCls: args.prefixCls || localPrefixCls,
        rootPrefixCls: args.rootPrefixCls,
        transitionName,
        hasTransitionName,
        style: { top: defaultTop },
        getContainer: getContainer || args.getPopupContainer,
        maxCount,
        name: 'message',
    }, (instance) => {
        if (messageInstance) {
            callback(messageInstance);
            return;
        }
        messageInstance = instance;
        callback(instance);
    });
}
const typeToIcon = {
    info: InfoCircleFilled,
    success: CheckCircleFilled,
    error: CloseCircleFilled,
    warning: ExclamationCircleFilled,
    loading: LoadingOutlined,
};
function notice(args) {
    const duration = args.duration !== undefined ? args.duration : defaultDuration;
    const target = args.key || getKeyThenIncreaseKey();
    const closePromise = new Promise(resolve => {
        const callback = () => {
            if (typeof args.onClose === 'function') {
                args.onClose();
            }
            return resolve(true);
        };
        getMessageInstance(args, instance => {
            instance.notice({
                key: target,
                duration,
                style: args.style || {},
                class: args.class,
                content: ({ prefixCls }) => {
                    const Icon = typeToIcon[args.type];
                    const iconNode = Icon ? <Icon /> : '';
                    const messageClass = classNames(`${prefixCls}-custom-content`, {
                        [`${prefixCls}-${args.type}`]: args.type,
                        [`${prefixCls}-rtl`]: rtl === true,
                    });
                    return (<div class={messageClass}>
              {typeof args.icon === 'function' ? args.icon() : args.icon || iconNode}
              <span>{typeof args.content === 'function' ? args.content() : args.content}</span>
            </div>);
                },
                onClose: callback,
                onClick: args.onClick,
            });
        });
    });
    const result = () => {
        if (messageInstance) {
            messageInstance.removeNotice(target);
        }
    };
    result.then = (filled, rejected) => closePromise.then(filled, rejected);
    result.promise = closePromise;
    return result;
}
function isArgsProps(content) {
    return (Object.prototype.toString.call(content) === '[object Object]' &&
        !!content.content);
}
const api = {
    open: notice,
    config: setMessageConfig,
    destroy(messageKey) {
        if (messageInstance) {
            if (messageKey) {
                const { removeNotice } = messageInstance;
                removeNotice(messageKey);
            }
            else {
                const { destroy } = messageInstance;
                destroy();
                messageInstance = null;
            }
        }
    },
};
export function attachTypeApi(originalApi, type) {
    originalApi[type] = (content, duration, onClose) => {
        if (isArgsProps(content)) {
            return originalApi.open(Object.assign(Object.assign({}, content), { type }));
        }
        if (typeof duration === 'function') {
            onClose = duration;
            duration = undefined;
        }
        return originalApi.open({ content, duration, type, onClose });
    };
}
['success', 'info', 'warning', 'error', 'loading'].forEach(type => attachTypeApi(api, type));
api.warn = api.warning;
/** @private test Only function. Not work on production */
export const getInstance = () => (process.env.NODE_ENV === 'test' ? messageInstance : null);
export default api;
