var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { inject, cloneVNode, isVNode, defineComponent, nextTick } from 'vue';
import debounce from 'lodash-es/debounce';
import PropTypes from '../_util/vue-types';
import { getComponent, getSlot } from '../_util/props-util';
import initDefaultProps from '../_util/props-util/initDefaultProps';
import { defaultConfigProvider } from '../config-provider';
export const spinProps = () => ({
    prefixCls: String,
    spinning: { type: Boolean, default: undefined },
    size: String,
    wrapperClassName: String,
    tip: PropTypes.any,
    delay: Number,
    indicator: PropTypes.any,
});
// Render indicator
let defaultIndicator = null;
function shouldDelay(spinning, delay) {
    return !!spinning && !!delay && !isNaN(Number(delay));
}
export function setDefaultIndicator(Content) {
    const Indicator = Content.indicator;
    defaultIndicator = typeof Indicator === 'function' ? Indicator : () => <Indicator />;
}
export default defineComponent({
    name: 'ASpin',
    inheritAttrs: false,
    props: initDefaultProps(spinProps(), {
        size: 'default',
        spinning: true,
        wrapperClassName: '',
    }),
    setup() {
        return {
            originalUpdateSpinning: null,
            configProvider: inject('configProvider', defaultConfigProvider),
        };
    },
    data() {
        const { spinning, delay } = this;
        const shouldBeDelayed = shouldDelay(spinning, delay);
        return {
            sSpinning: spinning && !shouldBeDelayed,
        };
    },
    created() {
        this.originalUpdateSpinning = this.updateSpinning;
        this.debouncifyUpdateSpinning(this.$props);
    },
    mounted() {
        this.updateSpinning();
    },
    updated() {
        nextTick(() => {
            this.debouncifyUpdateSpinning();
            this.updateSpinning();
        });
    },
    beforeUnmount() {
        this.cancelExistingSpin();
    },
    methods: {
        debouncifyUpdateSpinning(props) {
            const { delay } = props || this.$props;
            if (delay) {
                this.cancelExistingSpin();
                this.updateSpinning = debounce(this.originalUpdateSpinning, delay);
            }
        },
        updateSpinning() {
            const { spinning, sSpinning } = this;
            if (sSpinning !== spinning) {
                this.sSpinning = spinning;
            }
        },
        cancelExistingSpin() {
            const { updateSpinning } = this;
            if (updateSpinning && updateSpinning.cancel) {
                updateSpinning.cancel();
            }
        },
        renderIndicator(prefixCls) {
            const dotClassName = `${prefixCls}-dot`;
            let indicator = getComponent(this, 'indicator');
            // should not be render default indicator when indicator value is null
            if (indicator === null) {
                return null;
            }
            if (Array.isArray(indicator)) {
                indicator = indicator.length === 1 ? indicator[0] : indicator;
            }
            if (isVNode(indicator)) {
                return cloneVNode(indicator, { class: dotClassName });
            }
            if (defaultIndicator && isVNode(defaultIndicator())) {
                return cloneVNode(defaultIndicator(), { class: dotClassName });
            }
            return (<span class={`${dotClassName} ${prefixCls}-dot-spin`}>
          <i class={`${prefixCls}-dot-item`}/>
          <i class={`${prefixCls}-dot-item`}/>
          <i class={`${prefixCls}-dot-item`}/>
          <i class={`${prefixCls}-dot-item`}/>
        </span>);
        },
    },
    render() {
        var _a, _b;
        const { size, prefixCls: customizePrefixCls, tip = (_b = (_a = this.$slots).tip) === null || _b === void 0 ? void 0 : _b.call(_a), wrapperClassName, } = this.$props;
        const _c = this.$attrs, { class: cls, style } = _c, divProps = __rest(_c, ["class", "style"]);
        const { getPrefixCls, direction } = this.configProvider;
        const prefixCls = getPrefixCls('spin', customizePrefixCls);
        const { sSpinning } = this;
        const spinClassName = {
            [prefixCls]: true,
            [`${prefixCls}-sm`]: size === 'small',
            [`${prefixCls}-lg`]: size === 'large',
            [`${prefixCls}-spinning`]: sSpinning,
            [`${prefixCls}-show-text`]: !!tip,
            [`${prefixCls}-rtl`]: direction === 'rtl',
            [cls]: !!cls,
        };
        const spinElement = (<div {...divProps} style={style} class={spinClassName}>
        {this.renderIndicator(prefixCls)}
        {tip ? <div class={`${prefixCls}-text`}>{tip}</div> : null}
      </div>);
        const children = getSlot(this);
        if (children && children.length) {
            const containerClassName = {
                [`${prefixCls}-container`]: true,
                [`${prefixCls}-blur`]: sSpinning,
            };
            return (<div class={[`${prefixCls}-nested-loading`, wrapperClassName]}>
          {sSpinning && <div key="loading">{spinElement}</div>}
          <div class={containerClassName} key="container">
            {children}
          </div>
        </div>);
        }
        return spinElement;
    },
});
