import { defineComponent, ref, Transition } from 'vue';
import { flattenChildren } from '../../_util/props-util';
import classNames from '../../_util/classNames';
import { mobileProps } from './interface';
export default defineComponent({
    name: 'MobilePopupInner',
    inheritAttrs: false,
    props: mobileProps,
    emits: ['mouseenter', 'mouseleave', 'mousedown', 'touchstart', 'align'],
    setup(props, { expose, slots }) {
        const elementRef = ref();
        expose({
            forceAlign: () => { },
            getElement: () => elementRef.value,
        });
        return () => {
            var _a;
            const { zIndex, visible, prefixCls, mobile: { popupClassName, popupStyle, popupMotion = {}, popupRender } = {}, } = props;
            // ======================== Render ========================
            const mergedStyle = Object.assign({ zIndex }, popupStyle);
            let childNode = flattenChildren((_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots));
            // Wrapper when multiple children
            if (childNode.length > 1) {
                childNode = <div class={`${prefixCls}-content`}>{childNode}</div>;
            }
            // Mobile support additional render
            if (popupRender) {
                childNode = popupRender(childNode);
            }
            const mergedClassName = classNames(prefixCls, popupClassName);
            return (<Transition ref={elementRef} {...popupMotion}>
          {visible ? (<div class={mergedClassName} style={mergedStyle}>
              {childNode}
            </div>) : null}
        </Transition>);
        };
    },
});
