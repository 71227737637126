var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { defineComponent, ref, onMounted } from 'vue';
/**
 * Wrap of sub component which need use as Button capacity (like Icon component).
 * This helps accessibility reader to tread as a interactive button to operation.
 */
import KeyCode from './KeyCode';
const inlineStyle = {
    border: 0,
    background: 'transparent',
    padding: 0,
    lineHeight: 'inherit',
    display: 'inline-block',
};
const TransButton = defineComponent({
    name: 'TransButton',
    inheritAttrs: false,
    props: {
        noStyle: { type: Boolean, default: undefined },
        onClick: Function,
        disabled: { type: Boolean, default: undefined },
        autofocus: { type: Boolean, default: undefined },
    },
    setup(props, { slots, emit, attrs, expose }) {
        const domRef = ref();
        const onKeyDown = (event) => {
            const { keyCode } = event;
            if (keyCode === KeyCode.ENTER) {
                event.preventDefault();
            }
        };
        const onKeyUp = (event) => {
            const { keyCode } = event;
            if (keyCode === KeyCode.ENTER) {
                emit('click', event);
            }
        };
        const onClick = (e) => {
            emit('click', e);
        };
        const focus = () => {
            if (domRef.value) {
                domRef.value.focus();
            }
        };
        const blur = () => {
            if (domRef.value) {
                domRef.value.blur();
            }
        };
        onMounted(() => {
            if (props.autofocus) {
                focus();
            }
        });
        expose({
            focus,
            blur,
        });
        return () => {
            var _a;
            const { noStyle, disabled } = props, restProps = __rest(props, ["noStyle", "disabled"]);
            let mergedStyle = {};
            if (!noStyle) {
                mergedStyle = Object.assign({}, inlineStyle);
            }
            if (disabled) {
                mergedStyle.pointerEvents = 'none';
            }
            return (<div role="button" tabindex={0} ref={domRef} {...restProps} {...attrs} onClick={onClick} onKeydown={onKeyDown} onKeyup={onKeyUp} style={Object.assign(Object.assign({}, mergedStyle), (attrs.style || {}))}>
          {(_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots)}
        </div>);
        };
    },
});
export default TransButton;
