import { isVNode, defineComponent, renderSlot } from 'vue';
import Tabs from '../tabs';
import Row from '../row';
import Col from '../col';
import PropTypes from '../_util/vue-types';
import { flattenChildren, isEmptyElement, filterEmptyWithUndefined } from '../_util/props-util';
import isPlainObject from 'lodash-es/isPlainObject';
import useConfigInject from '../_util/hooks/useConfigInject';
import devWarning from '../vc-util/devWarning';
const { TabPane } = Tabs;
export const cardProps = () => ({
    prefixCls: String,
    title: PropTypes.any,
    extra: PropTypes.any,
    bordered: { type: Boolean, default: true },
    bodyStyle: { type: Object, default: undefined },
    headStyle: { type: Object, default: undefined },
    loading: { type: Boolean, default: false },
    hoverable: { type: Boolean, default: false },
    type: { type: String },
    size: { type: String },
    actions: PropTypes.any,
    tabList: {
        type: Array,
    },
    tabBarExtraContent: PropTypes.any,
    activeTabKey: String,
    defaultActiveTabKey: String,
    cover: PropTypes.any,
    onTabChange: {
        type: Function,
    },
});
const Card = defineComponent({
    name: 'ACard',
    props: cardProps(),
    slots: ['title', 'extra', 'tabBarExtraContent', 'actions', 'cover', 'customTab'],
    setup(props, { slots }) {
        const { prefixCls, direction, size } = useConfigInject('card', props);
        const getAction = (actions) => {
            const actionList = actions.map((action, index) => (isVNode(action) && !isEmptyElement(action)) || !isVNode(action) ? (<li style={{ width: `${100 / actions.length}%` }} key={`action-${index}`}>
            <span>{action}</span>
          </li>) : null);
            return actionList;
        };
        const triggerTabChange = (key) => {
            var _a;
            (_a = props.onTabChange) === null || _a === void 0 ? void 0 : _a.call(props, key);
        };
        const isContainGrid = (obj = []) => {
            let containGrid;
            obj.forEach(element => {
                if (element && isPlainObject(element.type) && element.type.__ANT_CARD_GRID) {
                    containGrid = true;
                }
            });
            return containGrid;
        };
        return () => {
            var _a, _b, _c, _d, _e, _f;
            const { headStyle = {}, bodyStyle = {}, loading, bordered = true, type, tabList, hoverable, activeTabKey, defaultActiveTabKey, tabBarExtraContent = filterEmptyWithUndefined((_a = slots.tabBarExtraContent) === null || _a === void 0 ? void 0 : _a.call(slots)), title = filterEmptyWithUndefined((_b = slots.title) === null || _b === void 0 ? void 0 : _b.call(slots)), extra = filterEmptyWithUndefined((_c = slots.extra) === null || _c === void 0 ? void 0 : _c.call(slots)), actions = filterEmptyWithUndefined((_d = slots.actions) === null || _d === void 0 ? void 0 : _d.call(slots)), cover = filterEmptyWithUndefined((_e = slots.cover) === null || _e === void 0 ? void 0 : _e.call(slots)), } = props;
            const children = flattenChildren((_f = slots.default) === null || _f === void 0 ? void 0 : _f.call(slots));
            const pre = prefixCls.value;
            const classString = {
                [`${pre}`]: true,
                [`${pre}-loading`]: loading,
                [`${pre}-bordered`]: bordered,
                [`${pre}-hoverable`]: !!hoverable,
                [`${pre}-contain-grid`]: isContainGrid(children),
                [`${pre}-contain-tabs`]: tabList && tabList.length,
                [`${pre}-${size.value}`]: size.value,
                [`${pre}-type-${type}`]: !!type,
                [`${pre}-rtl`]: direction.value === 'rtl',
            };
            const loadingBlockStyle = bodyStyle.padding === 0 || bodyStyle.padding === '0px' ? { padding: '24px' } : undefined;
            const block = <div class={`${pre}-loading-block`}/>;
            const loadingBlock = (<div class={`${pre}-loading-content`} style={loadingBlockStyle}>
          <Row gutter={8}>
            <Col span={22}>{block}</Col>
          </Row>
          <Row gutter={8}>
            <Col span={8}>{block}</Col>
            <Col span={15}>{block}</Col>
          </Row>
          <Row gutter={8}>
            <Col span={6}>{block}</Col>
            <Col span={18}>{block}</Col>
          </Row>
          <Row gutter={8}>
            <Col span={13}>{block}</Col>
            <Col span={9}>{block}</Col>
          </Row>
          <Row gutter={8}>
            <Col span={4}>{block}</Col>
            <Col span={3}>{block}</Col>
            <Col span={16}>{block}</Col>
          </Row>
        </div>);
            const hasActiveTabKey = activeTabKey !== undefined;
            const tabsProps = {
                size: 'large',
                [hasActiveTabKey ? 'activeKey' : 'defaultActiveKey']: hasActiveTabKey
                    ? activeTabKey
                    : defaultActiveTabKey,
                onChange: triggerTabChange,
                class: `${pre}-head-tabs`,
            };
            let head;
            const tabs = tabList && tabList.length ? (<Tabs {...tabsProps} v-slots={{ rightExtra: tabBarExtraContent ? () => tabBarExtraContent : null }}>
            {tabList.map(item => {
                    const { tab: temp, slots: itemSlots } = item;
                    const name = itemSlots === null || itemSlots === void 0 ? void 0 : itemSlots.tab;
                    if (process.env.NODE_ENV !== 'production')
                        devWarning(!itemSlots, 'Card', `tabList slots is deprecated, Please use \`customTab\` instead.`);
                    let tab = temp !== undefined ? temp : slots[name] ? slots[name](item) : null;
                    tab = renderSlot(slots, 'customTab', item, () => [tab]);
                    return <TabPane tab={tab} key={item.key} disabled={item.disabled}/>;
                })}
          </Tabs>) : null;
            if (title || extra || tabs) {
                head = (<div class={`${pre}-head`} style={headStyle}>
            <div class={`${pre}-head-wrapper`}>
              {title && <div class={`${pre}-head-title`}>{title}</div>}
              {extra && <div class={`${pre}-extra`}>{extra}</div>}
            </div>
            {tabs}
          </div>);
            }
            const coverDom = cover ? <div class={`${pre}-cover`}>{cover}</div> : null;
            const body = (<div class={`${pre}-body`} style={bodyStyle}>
          {loading ? loadingBlock : children}
        </div>);
            const actionDom = actions && actions.length ? <ul class={`${pre}-actions`}>{getAction(actions)}</ul> : null;
            return (<div class={classString} ref="cardContainerRef">
          {head}
          {coverDom}
          {children && children.length ? body : null}
          {actionDom}
        </div>);
        };
    },
});
export default Card;
