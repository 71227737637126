import { computed, ref, watchEffect, defineComponent } from 'vue';
import VcTreeSelect, { TreeNode, SHOW_ALL, SHOW_PARENT, SHOW_CHILD, treeSelectProps as vcTreeSelectProps, } from '../vc-tree-select';
import classNames from '../_util/classNames';
import initDefaultProps from '../_util/props-util/initDefaultProps';
import omit from '../_util/omit';
import PropTypes from '../_util/vue-types';
import useConfigInject from '../_util/hooks/useConfigInject';
import devWarning from '../vc-util/devWarning';
import getIcons from '../select/utils/iconUtil';
import renderSwitcherIcon from '../tree/utils/iconUtil';
import { warning } from '../vc-util/warning';
import { flattenChildren } from '../_util/props-util';
import { useInjectFormItemContext } from '../form/FormItemContext';
const getTransitionName = (rootPrefixCls, motion, transitionName) => {
    if (transitionName !== undefined) {
        return transitionName;
    }
    return `${rootPrefixCls}-${motion}`;
};
export function treeSelectProps() {
    return Object.assign(Object.assign({}, omit(vcTreeSelectProps(), [
        'showTreeIcon',
        'treeMotion',
        'inputIcon',
        'getInputElement',
        'treeLine',
        'customSlots',
    ])), { suffixIcon: PropTypes.any, size: { type: String }, bordered: { type: Boolean, default: undefined }, treeLine: { type: [Boolean, Object], default: undefined }, replaceFields: { type: Object }, 'onUpdate:value': { type: Function }, 'onUpdate:treeExpandedKeys': { type: Function }, 'onUpdate:searchValue': { type: Function } });
}
const TreeSelect = defineComponent({
    name: 'ATreeSelect',
    inheritAttrs: false,
    props: initDefaultProps(treeSelectProps(), {
        choiceTransitionName: '',
        listHeight: 256,
        treeIcon: false,
        listItemHeight: 26,
        bordered: true,
    }),
    slots: [
        'title',
        'titleRender',
        'placeholder',
        'maxTagPlaceholder',
        'treeIcon',
        'switcherIcon',
        'notFoundContent',
    ],
    setup(props, { attrs, slots, expose, emit }) {
        warning(!(props.treeData === undefined && slots.default), '`children` of TreeSelect is deprecated. Please use `treeData` instead.');
        watchEffect(() => {
            if (process.env.NODE_ENV !== 'production')
                devWarning(props.multiple !== false || !props.treeCheckable, 'TreeSelect', '`multiple` will always be `true` when `treeCheckable` is true');
            devWarning(props.replaceFields === undefined, 'TreeSelect', '`replaceFields` is deprecated, please use fieldNames instead');
        });
        const formItemContext = useInjectFormItemContext();
        const { prefixCls, renderEmpty, direction, virtual, dropdownMatchSelectWidth, size, getPopupContainer, getPrefixCls, } = useConfigInject('select', props);
        const rootPrefixCls = computed(() => getPrefixCls());
        const transitionName = computed(() => getTransitionName(rootPrefixCls.value, 'slide-up', props.transitionName));
        const choiceTransitionName = computed(() => getTransitionName(rootPrefixCls.value, '', props.choiceTransitionName));
        const treePrefixCls = computed(() => getPrefixCls('select-tree', props.prefixCls));
        const treeSelectPrefixCls = computed(() => getPrefixCls('tree-select', props.prefixCls));
        const mergedDropdownClassName = computed(() => classNames(props.dropdownClassName, `${treeSelectPrefixCls.value}-dropdown`, {
            [`${treeSelectPrefixCls.value}-dropdown-rtl`]: direction.value === 'rtl',
        }));
        const isMultiple = computed(() => !!(props.treeCheckable || props.multiple));
        const treeSelectRef = ref();
        expose({
            focus() {
                var _a, _b;
                (_b = (_a = treeSelectRef.value).focus) === null || _b === void 0 ? void 0 : _b.call(_a);
            },
            blur() {
                var _a, _b;
                (_b = (_a = treeSelectRef.value).blur) === null || _b === void 0 ? void 0 : _b.call(_a);
            },
        });
        const handleChange = (...args) => {
            emit('update:value', args[0]);
            emit('change', ...args);
            formItemContext.onFieldChange();
        };
        const handleTreeExpand = (keys) => {
            emit('update:treeExpandedKeys', keys);
            emit('treeExpand', keys);
        };
        const handleSearch = (value) => {
            emit('update:searchValue', value);
            emit('search', value);
        };
        const handleBlur = (e) => {
            emit('blur', e);
            formItemContext.onFieldBlur();
        };
        return () => {
            var _a, _b;
            const { notFoundContent = (_a = slots.notFoundContent) === null || _a === void 0 ? void 0 : _a.call(slots), prefixCls: customizePrefixCls, bordered, listHeight, listItemHeight, multiple, treeIcon, treeLine, switcherIcon = (_b = slots.switcherIcon) === null || _b === void 0 ? void 0 : _b.call(slots), fieldNames = props.replaceFields, id = formItemContext.id.value, } = props;
            // ===================== Icons =====================
            const { suffixIcon, removeIcon, clearIcon } = getIcons(Object.assign(Object.assign({}, props), { multiple: isMultiple.value, prefixCls: prefixCls.value }), slots);
            // ===================== Empty =====================
            let mergedNotFound;
            if (notFoundContent !== undefined) {
                mergedNotFound = notFoundContent;
            }
            else {
                mergedNotFound = renderEmpty.value('Select');
            }
            // ==================== Render =====================
            const selectProps = omit(props, [
                'suffixIcon',
                'itemIcon',
                'removeIcon',
                'clearIcon',
                'switcherIcon',
                'bordered',
                'onUpdate:value',
                'onUpdate:treeExpandedKeys',
                'onUpdate:searchValue',
            ]);
            const mergedClassName = classNames(!customizePrefixCls && treeSelectPrefixCls.value, {
                [`${prefixCls.value}-lg`]: size.value === 'large',
                [`${prefixCls.value}-sm`]: size.value === 'small',
                [`${prefixCls.value}-rtl`]: direction.value === 'rtl',
                [`${prefixCls.value}-borderless`]: !bordered,
            }, attrs.class);
            const otherProps = {};
            if (props.treeData === undefined && slots.default) {
                otherProps.children = flattenChildren(slots.default());
            }
            return (<VcTreeSelect {...attrs} {...selectProps} virtual={virtual.value} dropdownMatchSelectWidth={dropdownMatchSelectWidth.value} id={id} fieldNames={fieldNames} ref={treeSelectRef} prefixCls={prefixCls.value} class={mergedClassName} listHeight={listHeight} listItemHeight={listItemHeight} treeLine={!!treeLine} inputIcon={suffixIcon} multiple={multiple} removeIcon={removeIcon} clearIcon={clearIcon} switcherIcon={(nodeProps) => renderSwitcherIcon(treePrefixCls.value, switcherIcon, treeLine, nodeProps)} showTreeIcon={treeIcon} notFoundContent={mergedNotFound} getPopupContainer={getPopupContainer.value} treeMotion={null} dropdownClassName={mergedDropdownClassName.value} choiceTransitionName={choiceTransitionName.value} onChange={handleChange} onBlur={handleBlur} onSearch={handleSearch} onTreeExpand={handleTreeExpand} v-slots={Object.assign(Object.assign({}, slots), { treeCheckable: () => <span class={`${prefixCls.value}-tree-checkbox-inner`}/> })} {...otherProps} transitionName={transitionName.value} customSlots={Object.assign(Object.assign({}, slots), { treeCheckable: () => <span class={`${prefixCls.value}-tree-checkbox-inner`}/> })} maxTagPlaceholder={props.maxTagPlaceholder || slots.maxTagPlaceholder}/>);
        };
    },
});
/* istanbul ignore next */
export const TreeSelectNode = TreeNode;
export default Object.assign(TreeSelect, {
    TreeNode,
    SHOW_ALL: SHOW_ALL,
    SHOW_PARENT: SHOW_PARENT,
    SHOW_CHILD: SHOW_CHILD,
    install: (app) => {
        app.component(TreeSelect.name, TreeSelect);
        app.component(TreeSelectNode.displayName, TreeSelectNode);
        return app;
    },
});
