import { computed, defineComponent, ref } from 'vue';
import Tooltip from '../tooltip';
import abstractTooltipProps from '../tooltip/abstractTooltipProps';
import PropTypes from '../_util/vue-types';
import { filterEmpty, initDefaultProps } from '../_util/props-util';
import { withInstall } from '../_util/type';
import useConfigInject from '../_util/hooks/useConfigInject';
import omit from '../_util/omit';
import { getTransitionName } from '../_util/transition';
import { tooltipDefaultProps } from '../tooltip/Tooltip';
export const popoverProps = () => (Object.assign(Object.assign({}, abstractTooltipProps()), { content: PropTypes.any, title: PropTypes.any }));
const Popover = defineComponent({
    name: 'APopover',
    props: initDefaultProps(popoverProps(), Object.assign(Object.assign({}, tooltipDefaultProps()), { trigger: 'hover', transitionName: 'zoom-big', placement: 'top', mouseEnterDelay: 0.1, mouseLeaveDelay: 0.1 })),
    setup(props, { expose, slots }) {
        const tooltipRef = ref();
        expose({
            getPopupDomNode: () => {
                var _a, _b;
                return (_b = (_a = tooltipRef.value) === null || _a === void 0 ? void 0 : _a.getPopupDomNode) === null || _b === void 0 ? void 0 : _b.call(_a);
            },
        });
        const { prefixCls, configProvider } = useConfigInject('popover', props);
        const rootPrefixCls = computed(() => configProvider.getPrefixCls());
        const getOverlay = () => {
            var _a, _b;
            const { title = filterEmpty((_a = slots.title) === null || _a === void 0 ? void 0 : _a.call(slots)), content = filterEmpty((_b = slots.content) === null || _b === void 0 ? void 0 : _b.call(slots)) } = props;
            const hasTitle = !!(Array.isArray(title) ? title.length : title);
            const hasContent = !!(Array.isArray(content) ? content.length : title);
            if (!hasTitle && !hasContent)
                return undefined;
            return (<>
          {hasTitle && <div class={`${prefixCls.value}-title`}>{title}</div>}
          <div class={`${prefixCls.value}-inner-content`}>{content}</div>
        </>);
        };
        return () => {
            return (<Tooltip {...omit(props, ['title', 'content'])} prefixCls={prefixCls.value} ref={tooltipRef} v-slots={{ title: getOverlay, default: slots.default }} transitionName={getTransitionName(rootPrefixCls.value, 'zoom-big', props.transitionName)}/>);
        };
    },
});
export default withInstall(Popover);
