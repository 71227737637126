import PropTypes from '../_util/vue-types';
import buttonTypes from '../button/buttonTypes';
const dropdownProps = () => ({
    arrow: {
        type: [Boolean, Object],
        default: undefined,
    },
    trigger: {
        type: [Array, String],
    },
    overlay: PropTypes.any,
    visible: { type: Boolean, default: undefined },
    disabled: { type: Boolean, default: undefined },
    align: { type: Object },
    getPopupContainer: Function,
    prefixCls: String,
    transitionName: String,
    placement: String,
    overlayClassName: String,
    overlayStyle: { type: Object, default: undefined },
    forceRender: { type: Boolean, default: undefined },
    mouseEnterDelay: Number,
    mouseLeaveDelay: Number,
    openClassName: String,
    minOverlayWidthMatchTrigger: { type: Boolean, default: undefined },
    destroyPopupOnHide: { type: Boolean, default: undefined },
    onVisibleChange: {
        type: Function,
    },
    'onUpdate:visible': {
        type: Function,
    },
});
const buttonTypesProps = buttonTypes();
const dropdownButtonProps = () => (Object.assign(Object.assign({}, dropdownProps()), { type: buttonTypesProps.type, size: String, htmlType: buttonTypesProps.htmlType, href: String, disabled: { type: Boolean, default: undefined }, prefixCls: String, icon: PropTypes.any, title: String, loading: buttonTypesProps.loading, onClick: {
        type: Function,
    } }));
export { dropdownProps, dropdownButtonProps };
export default dropdownProps;
