import { defineComponent } from 'vue';
import Tree from './Tree';
import { TreeNode as VcTreeNode } from '../vc-tree';
import DirectoryTree from './DirectoryTree';
import { treeNodeProps } from '../vc-tree/props';
/* istanbul ignore next */
const TreeNode = defineComponent(Object.assign(Object.assign({}, VcTreeNode), { name: 'ATreeNode', props: treeNodeProps }));
export { DirectoryTree, TreeNode };
export default Object.assign(Tree, {
    DirectoryTree,
    TreeNode,
    install: (app) => {
        app.component(Tree.name, Tree);
        app.component(TreeNode.name, TreeNode);
        app.component(DirectoryTree.name, DirectoryTree);
        return app;
    },
});
