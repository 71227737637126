import canUseDom from '../../_util/canUseDom';
const MARK_KEY = `vc-util-key`;
function getMark({ mark } = {}) {
    if (mark) {
        return mark.startsWith('data-') ? mark : `data-${mark}`;
    }
    return MARK_KEY;
}
function getContainer(option) {
    if (option.attachTo) {
        return option.attachTo;
    }
    const head = document.querySelector('head');
    return head || document.body;
}
export function injectCSS(css, option = {}) {
    var _a, _b;
    if (!canUseDom()) {
        return null;
    }
    const styleNode = document.createElement('style');
    if ((_a = option.csp) === null || _a === void 0 ? void 0 : _a.nonce) {
        styleNode.nonce = (_b = option.csp) === null || _b === void 0 ? void 0 : _b.nonce;
    }
    styleNode.innerHTML = css;
    const container = getContainer(option);
    const { firstChild } = container;
    if (option.prepend && container.prepend) {
        // Use `prepend` first
        container.prepend(styleNode);
    }
    else if (option.prepend && firstChild) {
        // Fallback to `insertBefore` like IE not support `prepend`
        container.insertBefore(styleNode, firstChild);
    }
    else {
        container.appendChild(styleNode);
    }
    return styleNode;
}
const containerCache = new Map();
function findExistNode(key, option = {}) {
    const container = getContainer(option);
    return Array.from(containerCache.get(container).children).find(node => node.tagName === 'STYLE' && node.getAttribute(getMark(option)) === key);
}
export function removeCSS(key, option = {}) {
    var _a;
    const existNode = findExistNode(key, option);
    (_a = existNode === null || existNode === void 0 ? void 0 : existNode.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(existNode);
}
export function updateCSS(css, key, option = {}) {
    var _a, _b, _c;
    const container = getContainer(option);
    // Get real parent
    if (!containerCache.has(container)) {
        const placeholderStyle = injectCSS('', option);
        const { parentNode } = placeholderStyle;
        containerCache.set(container, parentNode);
        parentNode.removeChild(placeholderStyle);
    }
    const existNode = findExistNode(key, option);
    if (existNode) {
        if (((_a = option.csp) === null || _a === void 0 ? void 0 : _a.nonce) && existNode.nonce !== ((_b = option.csp) === null || _b === void 0 ? void 0 : _b.nonce)) {
            existNode.nonce = (_c = option.csp) === null || _c === void 0 ? void 0 : _c.nonce;
        }
        if (existNode.innerHTML !== css) {
            existNode.innerHTML = css;
        }
        return existNode;
    }
    const newNode = injectCSS(css, option);
    newNode.setAttribute(getMark(option), key);
    return newNode;
}
