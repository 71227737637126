import { defineComponent, ref } from 'vue';
import Select, { selectProps } from '../select';
import { isValidElement, flattenChildren } from '../_util/props-util';
import warning from '../_util/warning';
import Option from './Option';
import OptGroup from './OptGroup';
import omit from '../_util/omit';
import useConfigInject from '../_util/hooks/useConfigInject';
function isSelectOptionOrSelectOptGroup(child) {
    var _a, _b;
    return ((_a = child === null || child === void 0 ? void 0 : child.type) === null || _a === void 0 ? void 0 : _a.isSelectOption) || ((_b = child === null || child === void 0 ? void 0 : child.type) === null || _b === void 0 ? void 0 : _b.isSelectOptGroup);
}
export const autoCompleteProps = () => (Object.assign(Object.assign({}, omit(selectProps(), ['loading', 'mode', 'optionLabelProp', 'labelInValue'])), { dataSource: Array, dropdownMenuStyle: {
        type: Object,
        default: undefined,
    }, 
    // optionLabelProp: String,
    dropdownMatchSelectWidth: { type: [Number, Boolean], default: true }, prefixCls: String, showSearch: { type: Boolean, default: undefined }, transitionName: String, choiceTransitionName: { type: String, default: 'zoom' }, autofocus: { type: Boolean, default: undefined }, backfill: { type: Boolean, default: undefined }, 
    // optionLabelProp: PropTypes.string.def('children'),
    filterOption: { type: [Boolean, Function], default: false }, defaultActiveFirstOption: { type: Boolean, default: true } }));
export const AutoCompleteOption = Option;
export const AutoCompleteOptGroup = OptGroup;
const AutoComplete = defineComponent({
    name: 'AAutoComplete',
    inheritAttrs: false,
    props: autoCompleteProps(),
    // emits: ['change', 'select', 'focus', 'blur'],
    slots: ['option'],
    setup(props, { slots, attrs, expose }) {
        warning(!('dataSource' in slots), 'AutoComplete', '`dataSource` slot is deprecated, please use props `options` instead.');
        warning(!('options' in slots), 'AutoComplete', '`options` slot is deprecated, please use props `options` instead.');
        const selectRef = ref();
        const getInputElement = () => {
            var _a;
            const children = flattenChildren((_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots));
            const element = children.length ? children[0] : undefined;
            return element;
        };
        const focus = () => {
            var _a;
            (_a = selectRef.value) === null || _a === void 0 ? void 0 : _a.focus();
        };
        const blur = () => {
            var _a;
            (_a = selectRef.value) === null || _a === void 0 ? void 0 : _a.blur();
        };
        expose({
            focus,
            blur,
        });
        const { prefixCls } = useConfigInject('select', props);
        return () => {
            var _a, _b, _c;
            const { size, dataSource, notFoundContent = (_a = slots.notFoundContent) === null || _a === void 0 ? void 0 : _a.call(slots) } = props;
            let optionChildren;
            const { class: className } = attrs;
            const cls = {
                [className]: !!className,
                [`${prefixCls.value}-lg`]: size === 'large',
                [`${prefixCls.value}-sm`]: size === 'small',
                [`${prefixCls.value}-show-search`]: true,
                [`${prefixCls.value}-auto-complete`]: true,
            };
            if (props.options === undefined) {
                const childArray = ((_b = slots.dataSource) === null || _b === void 0 ? void 0 : _b.call(slots)) || ((_c = slots.options) === null || _c === void 0 ? void 0 : _c.call(slots)) || [];
                if (childArray.length && isSelectOptionOrSelectOptGroup(childArray[0])) {
                    optionChildren = childArray;
                }
                else {
                    optionChildren = dataSource
                        ? dataSource.map((item) => {
                            if (isValidElement(item)) {
                                return item;
                            }
                            switch (typeof item) {
                                case 'string':
                                    return (<Option key={item} value={item}>
                        {item}
                      </Option>);
                                case 'object':
                                    return (<Option key={item.value} value={item.value}>
                        {item.text}
                      </Option>);
                                default:
                                    throw new Error('AutoComplete[dataSource] only supports type `string[] | Object[]`.');
                            }
                        })
                        : [];
                }
            }
            const selectProps = omit(Object.assign(Object.assign(Object.assign({}, props), attrs), { mode: Select.SECRET_COMBOBOX_MODE_DO_NOT_USE, 
                // optionLabelProp,
                getInputElement,
                notFoundContent, 
                // placeholder: '',
                class: cls, ref: selectRef }), ['dataSource', 'loading']);
            return (<Select {...selectProps} v-slots={omit(slots, ['default', 'dataSource', 'options'])}>
          {optionChildren}
        </Select>);
        };
    },
});
/* istanbul ignore next */
export default Object.assign(AutoComplete, {
    Option,
    OptGroup,
    install(app) {
        app.component(AutoComplete.name, AutoComplete);
        app.component(Option.displayName, Option);
        app.component(OptGroup.displayName, OptGroup);
        return app;
    },
});
