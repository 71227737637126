var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import VcCascader, { cascaderProps as vcCascaderProps } from '../vc-cascader';
import RightOutlined from '@ant-design/icons-vue/RightOutlined';
import LoadingOutlined from '@ant-design/icons-vue/LoadingOutlined';
import LeftOutlined from '@ant-design/icons-vue/LeftOutlined';
import getIcons from '../select/utils/iconUtil';
import { withInstall } from '../_util/type';
import omit from '../_util/omit';
import { computed, defineComponent, ref, watchEffect } from 'vue';
import PropTypes from '../_util/vue-types';
import { initDefaultProps } from '../_util/props-util';
import useConfigInject from '../_util/hooks/useConfigInject';
import classNames from '../_util/classNames';
import devWarning from '../vc-util/devWarning';
import { getTransitionDirection, getTransitionName } from '../_util/transition';
import { useInjectFormItemContext } from '../form';
function highlightKeyword(str, lowerKeyword, prefixCls) {
    const cells = str
        .toLowerCase()
        .split(lowerKeyword)
        .reduce((list, cur, index) => (index === 0 ? [cur] : [...list, lowerKeyword, cur]), []);
    const fillCells = [];
    let start = 0;
    cells.forEach((cell, index) => {
        const end = start + cell.length;
        let originWorld = str.slice(start, end);
        start = end;
        if (index % 2 === 1) {
            originWorld = (<span class={`${prefixCls}-menu-item-keyword`} key="seperator">
          {originWorld}
        </span>);
        }
        fillCells.push(originWorld);
    });
    return fillCells;
}
const defaultSearchRender = ({ inputValue, path, prefixCls, fieldNames, }) => {
    const optionList = [];
    // We do lower here to save perf
    const lower = inputValue.toLowerCase();
    path.forEach((node, index) => {
        if (index !== 0) {
            optionList.push(' / ');
        }
        let label = node[fieldNames.label];
        const type = typeof label;
        if (type === 'string' || type === 'number') {
            label = highlightKeyword(String(label), lower, prefixCls);
        }
        optionList.push(label);
    });
    return optionList;
};
export function cascaderProps() {
    return Object.assign(Object.assign({}, omit(vcCascaderProps(), ['customSlots', 'checkable', 'options'])), { multiple: { type: Boolean, default: undefined }, size: String, bordered: { type: Boolean, default: undefined }, placement: { type: String }, suffixIcon: PropTypes.any, options: Array, 'onUpdate:value': Function });
}
const Cascader = defineComponent({
    name: 'ACascader',
    inheritAttrs: false,
    props: initDefaultProps(cascaderProps(), {
        bordered: true,
        choiceTransitionName: '',
        allowClear: true,
    }),
    setup(props, { attrs, expose, slots, emit }) {
        const formItemContext = useInjectFormItemContext();
        const { prefixCls: cascaderPrefixCls, rootPrefixCls, getPrefixCls, direction, getPopupContainer, renderEmpty, size, } = useConfigInject('cascader', props);
        const prefixCls = computed(() => getPrefixCls('select', props.prefixCls));
        const isRtl = computed(() => direction.value === 'rtl');
        // =================== Warning =====================
        if (process.env.NODE_ENV !== 'production') {
            watchEffect(() => {
                if (process.env.NODE_ENV !== 'production')
                    devWarning(!props.multiple || !props.displayRender || !slots.displayRender, 'Cascader', '`displayRender` not work on `multiple`. Please use `tagRender` instead.');
            });
        }
        // ==================== Search =====================
        const mergedShowSearch = computed(() => {
            if (!props.showSearch) {
                return props.showSearch;
            }
            let searchConfig = {
                render: defaultSearchRender,
            };
            if (typeof props.showSearch === 'object') {
                searchConfig = Object.assign(Object.assign({}, searchConfig), props.showSearch);
            }
            return searchConfig;
        });
        // =================== Dropdown ====================
        const mergedDropdownClassName = computed(() => classNames(props.dropdownClassName || props.popupClassName, `${cascaderPrefixCls.value}-dropdown`, {
            [`${cascaderPrefixCls.value}-dropdown-rtl`]: isRtl.value,
        }));
        const selectRef = ref();
        expose({
            focus() {
                var _a;
                (_a = selectRef.value) === null || _a === void 0 ? void 0 : _a.focus();
            },
            blur() {
                var _a;
                (_a = selectRef.value) === null || _a === void 0 ? void 0 : _a.blur();
            },
        });
        const handleChange = (...args) => {
            emit('update:value', args[0]);
            emit('change', ...args);
            formItemContext.onFieldChange();
        };
        const handleBlur = (...args) => {
            emit('blur', ...args);
            formItemContext.onFieldBlur();
        };
        const mergedShowArrow = computed(() => props.showArrow !== undefined ? props.showArrow : props.loading || !props.multiple);
        const placement = computed(() => {
            if (props.placement !== undefined) {
                return props.placement;
            }
            return direction.value === 'rtl'
                ? 'bottomRight'
                : 'bottomLeft';
        });
        return () => {
            var _a, _b;
            const { notFoundContent = (_a = slots.notFoundContent) === null || _a === void 0 ? void 0 : _a.call(slots), expandIcon = (_b = slots.expandIcon) === null || _b === void 0 ? void 0 : _b.call(slots), multiple, bordered, allowClear, choiceTransitionName, transitionName, id = formItemContext.id.value } = props, restProps = __rest(props, ["notFoundContent", "expandIcon", "multiple", "bordered", "allowClear", "choiceTransitionName", "transitionName", "id"]);
            // =================== No Found ====================
            const mergedNotFoundContent = notFoundContent || renderEmpty.value('Cascader');
            // ===================== Icon ======================
            let mergedExpandIcon = expandIcon;
            if (!expandIcon) {
                mergedExpandIcon = isRtl.value ? <LeftOutlined /> : <RightOutlined />;
            }
            const loadingIcon = (<span class={`${prefixCls.value}-menu-item-loading-icon`}>
          <LoadingOutlined spin/>
        </span>);
            // ===================== Icons =====================
            const { suffixIcon, removeIcon, clearIcon } = getIcons(Object.assign(Object.assign({}, props), { multiple, prefixCls: prefixCls.value, showArrow: mergedShowArrow.value }), slots);
            return (<VcCascader {...restProps} {...attrs} id={id} prefixCls={prefixCls.value} class={[
                    cascaderPrefixCls.value,
                    {
                        [`${prefixCls.value}-lg`]: size.value === 'large',
                        [`${prefixCls.value}-sm`]: size.value === 'small',
                        [`${prefixCls.value}-rtl`]: isRtl.value,
                        [`${prefixCls.value}-borderless`]: !bordered,
                    },
                    attrs.class,
                ]} direction={direction.value} placement={placement.value} notFoundContent={mergedNotFoundContent} allowClear={allowClear} showSearch={mergedShowSearch.value} expandIcon={mergedExpandIcon} inputIcon={suffixIcon} removeIcon={removeIcon} clearIcon={clearIcon} loadingIcon={loadingIcon} checkable={!!multiple} dropdownClassName={mergedDropdownClassName.value} dropdownPrefixCls={cascaderPrefixCls.value} choiceTransitionName={getTransitionName(rootPrefixCls.value, '', choiceTransitionName)} transitionName={getTransitionName(rootPrefixCls.value, getTransitionDirection(placement.value), transitionName)} getPopupContainer={getPopupContainer.value} customSlots={Object.assign(Object.assign({}, slots), { checkable: () => <span class={`${cascaderPrefixCls.value}-checkbox-inner`}/> })} displayRender={props.displayRender || slots.displayRender} maxTagPlaceholder={props.maxTagPlaceholder || slots.maxTagPlaceholder} showArrow={props.showArrow} onChange={handleChange} onBlur={handleBlur} v-slots={slots} ref={selectRef}/>);
        };
    },
});
export default withInstall(Cascader);
