import KeyCode from '../_util/KeyCode';
import TextArea from '../input/TextArea';
import EnterOutlined from '@ant-design/icons-vue/EnterOutlined';
import { defineComponent, ref, reactive, watch, onMounted, computed } from 'vue';
const editableProps = () => ({
    prefixCls: String,
    value: String,
    maxlength: Number,
    autoSize: { type: [Boolean, Object] },
    onSave: Function,
    onCancel: Function,
    onEnd: Function,
    onChange: Function,
    originContent: String,
    direction: String,
});
const Editable = defineComponent({
    name: 'Editable',
    props: editableProps(),
    // emits: ['save', 'cancel', 'end', 'change'],
    setup(props, { emit, slots }) {
        const state = reactive({
            current: props.value || '',
            lastKeyCode: undefined,
            inComposition: false,
            cancelFlag: false,
        });
        watch(() => props.value, current => {
            state.current = current;
        });
        const textArea = ref();
        onMounted(() => {
            var _a;
            if (textArea.value) {
                const resizableTextArea = (_a = textArea.value) === null || _a === void 0 ? void 0 : _a.resizableTextArea;
                const innerTextArea = resizableTextArea === null || resizableTextArea === void 0 ? void 0 : resizableTextArea.textArea;
                innerTextArea.focus();
                const { length } = innerTextArea.value;
                innerTextArea.setSelectionRange(length, length);
            }
        });
        function saveTextAreaRef(node) {
            textArea.value = node;
        }
        function onChange({ target: { value } }) {
            state.current = value.replace(/[\r\n]/g, '');
            emit('change', state.current);
        }
        function onCompositionStart() {
            state.inComposition = true;
        }
        function onCompositionEnd() {
            state.inComposition = false;
        }
        function onKeyDown(e) {
            const { keyCode } = e;
            if (keyCode === KeyCode.ENTER) {
                e.preventDefault();
            }
            // We don't record keyCode when IME is using
            if (state.inComposition)
                return;
            state.lastKeyCode = keyCode;
        }
        function onKeyUp(e) {
            const { keyCode, ctrlKey, altKey, metaKey, shiftKey } = e;
            // Check if it's a real key
            if (state.lastKeyCode === keyCode &&
                !state.inComposition &&
                !ctrlKey &&
                !altKey &&
                !metaKey &&
                !shiftKey) {
                if (keyCode === KeyCode.ENTER) {
                    confirmChange();
                    emit('end');
                }
                else if (keyCode === KeyCode.ESC) {
                    state.current = props.originContent;
                    emit('cancel');
                }
            }
        }
        function onBlur() {
            confirmChange();
        }
        function confirmChange() {
            emit('save', state.current.trim());
        }
        const textAreaClassName = computed(() => ({
            [`${props.prefixCls}`]: true,
            [`${props.prefixCls}-edit-content`]: true,
            [`${props.prefixCls}-rtl`]: props.direction === 'rtl',
        }));
        return () => (<div class={textAreaClassName.value}>
        <TextArea ref={saveTextAreaRef} maxlength={props.maxlength} value={state.current} onChange={onChange} onKeydown={onKeyDown} onKeyup={onKeyUp} onCompositionstart={onCompositionStart} onCompositionend={onCompositionEnd} onBlur={onBlur} rows={1} autoSize={props.autoSize === undefined || props.autoSize}/>
        {slots.enterIcon ? (slots.enterIcon({ className: `${props.prefixCls}-edit-content-confirm` })) : (<EnterOutlined class={`${props.prefixCls}-edit-content-confirm`}/>)}
      </div>);
    },
});
export default Editable;
