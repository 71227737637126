import { useInjectFormItemPrefix } from './context';
import { computed, defineComponent, ref, watch } from 'vue';
import { getTransitionGroupProps, TransitionGroup } from '../_util/transition';
import useConfigInject from '../_util/hooks/useConfigInject';
import collapseMotion from '../_util/collapseMotion';
export default defineComponent({
    name: 'ErrorList',
    props: ['errors', 'help', 'onDomErrorVisibleChange', 'helpStatus', 'warnings'],
    setup(props) {
        const { prefixCls: rootPrefixCls } = useConfigInject('', props);
        const { prefixCls, status } = useInjectFormItemPrefix();
        const baseClassName = computed(() => `${prefixCls.value}-item-explain`);
        const visible = computed(() => !!(props.errors && props.errors.length));
        const innerStatus = ref(status.value);
        // Memo status in same visible
        watch([visible, status], () => {
            if (visible.value) {
                innerStatus.value = status.value;
            }
        });
        return () => {
            var _a, _b;
            const colMItem = collapseMotion(`${rootPrefixCls.value}-show-help-item`);
            const transitionGroupProps = getTransitionGroupProps(`${rootPrefixCls.value}-show-help-item`, colMItem);
            transitionGroupProps.class = baseClassName.value;
            return ((_a = props.errors) === null || _a === void 0 ? void 0 : _a.length) ? (<TransitionGroup {...transitionGroupProps} tag="div">
          {(_b = props.errors) === null || _b === void 0 ? void 0 : _b.map((error, index) => (<div key={index} role="alert" class={innerStatus.value ? `${baseClassName.value}-${innerStatus.value}` : ''}>
              {error}
            </div>))}
        </TransitionGroup>) : null;
        };
    },
});
