import { computed, ref, defineComponent, nextTick } from 'vue';
import Transition, { getTransitionProps } from '../_util/transition';
import dialogPropTypes from './IDialogPropTypes';
import { offset } from './util';
const sentinelStyle = { width: 0, height: 0, overflow: 'hidden', outline: 'none' };
export default defineComponent({
    name: 'Content',
    inheritAttrs: false,
    props: Object.assign(Object.assign({}, dialogPropTypes()), { motionName: String, ariaId: String, onVisibleChanged: Function, onMousedown: Function, onMouseup: Function }),
    setup(props, { expose, slots, attrs }) {
        const sentinelStartRef = ref();
        const sentinelEndRef = ref();
        const dialogRef = ref();
        expose({
            focus: () => {
                var _a;
                (_a = sentinelStartRef.value) === null || _a === void 0 ? void 0 : _a.focus();
            },
            changeActive: next => {
                const { activeElement } = document;
                if (next && activeElement === sentinelEndRef.value) {
                    sentinelStartRef.value.focus();
                }
                else if (!next && activeElement === sentinelStartRef.value) {
                    sentinelEndRef.value.focus();
                }
            },
        });
        const transformOrigin = ref();
        const contentStyleRef = computed(() => {
            const { width, height } = props;
            const contentStyle = {};
            if (width !== undefined) {
                contentStyle.width = typeof width === 'number' ? `${width}px` : width;
            }
            if (height !== undefined) {
                contentStyle.height = typeof height === 'number' ? `${height}px` : height;
            }
            if (transformOrigin.value) {
                contentStyle.transformOrigin = transformOrigin.value;
            }
            return contentStyle;
        });
        const onPrepare = () => {
            nextTick(() => {
                if (dialogRef.value) {
                    const elementOffset = offset(dialogRef.value);
                    transformOrigin.value = props.mousePosition
                        ? `${props.mousePosition.x - elementOffset.left}px ${props.mousePosition.y - elementOffset.top}px`
                        : '';
                }
            });
        };
        const onVisibleChanged = (visible) => {
            props.onVisibleChanged(visible);
        };
        return () => {
            var _a, _b, _c, _d;
            const { prefixCls, footer = (_a = slots.footer) === null || _a === void 0 ? void 0 : _a.call(slots), title = (_b = slots.title) === null || _b === void 0 ? void 0 : _b.call(slots), ariaId, closable, closeIcon = (_c = slots.closeIcon) === null || _c === void 0 ? void 0 : _c.call(slots), onClose, bodyStyle, bodyProps, onMousedown, onMouseup, visible, modalRender = slots.modalRender, destroyOnClose, motionName, } = props;
            let footerNode;
            if (footer) {
                footerNode = <div class={`${prefixCls}-footer`}>{footer}</div>;
            }
            let headerNode;
            if (title) {
                headerNode = (<div class={`${prefixCls}-header`}>
            <div class={`${prefixCls}-title`} id={ariaId}>
              {title}
            </div>
          </div>);
            }
            let closer;
            if (closable) {
                closer = (<button type="button" onClick={onClose} aria-label="Close" class={`${prefixCls}-close`}>
            {closeIcon || <span class={`${prefixCls}-close-x`}/>}
          </button>);
            }
            const content = (<div class={`${prefixCls}-content`}>
          {closer}
          {headerNode}
          <div class={`${prefixCls}-body`} style={bodyStyle} {...bodyProps}>
            {(_d = slots.default) === null || _d === void 0 ? void 0 : _d.call(slots)}
          </div>
          {footerNode}
        </div>);
            const transitionProps = getTransitionProps(motionName);
            return (<Transition {...transitionProps} onBeforeEnter={onPrepare} onAfterEnter={() => onVisibleChanged(true)} onAfterLeave={() => onVisibleChanged(false)}>
          {visible || !destroyOnClose ? (<div {...attrs} ref={dialogRef} v-show={visible} key="dialog-element" role="document" style={[contentStyleRef.value, attrs.style]} class={[prefixCls, attrs.class]} onMousedown={onMousedown} onMouseup={onMouseup}>
              <div tabindex={0} ref={sentinelStartRef} style={sentinelStyle} aria-hidden="true"/>
              {modalRender ? modalRender({ originVNode: content }) : content}
              <div tabindex={0} ref={sentinelEndRef} style={sentinelStyle} aria-hidden="true"/>
            </div>) : null}
        </Transition>);
        };
    },
});
