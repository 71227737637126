import { computed, defineComponent } from 'vue';
import { presetPrimaryColors } from '@ant-design/colors';
import { Circle as VCCircle } from '../vc-progress';
import { getSuccessPercent, validProgress } from './utils';
import { progressProps } from './props';
function getPercentage({ percent, success, successPercent }) {
    const realSuccessPercent = validProgress(getSuccessPercent({ success, successPercent }));
    return [realSuccessPercent, validProgress(validProgress(percent) - realSuccessPercent)];
}
function getStrokeColor({ success = {}, strokeColor, }) {
    const { strokeColor: successColor } = success;
    return [successColor || presetPrimaryColors.green, strokeColor || null];
}
export default defineComponent({
    name: 'Circle',
    inheritAttrs: false,
    props: progressProps(),
    setup(props, { slots }) {
        const gapDeg = computed(() => {
            // Support gapDeg = 0 when type = 'dashboard'
            if (props.gapDegree || props.gapDegree === 0) {
                return props.gapDegree;
            }
            if (props.type === 'dashboard') {
                return 75;
            }
            return undefined;
        });
        const circleStyle = computed(() => {
            const circleSize = props.width || 120;
            return {
                width: typeof circleSize === 'number' ? `${circleSize}px` : circleSize,
                height: typeof circleSize === 'number' ? `${circleSize}px` : circleSize,
                fontSize: `${circleSize * 0.15 + 6}px`,
            };
        });
        const circleWidth = computed(() => props.strokeWidth || 6);
        const gapPos = computed(() => props.gapPosition || (props.type === 'dashboard' && 'bottom') || 'top');
        // using className to style stroke color
        const percent = computed(() => getPercentage(props));
        const isGradient = computed(() => Object.prototype.toString.call(props.strokeColor) === '[object Object]');
        const strokeColor = computed(() => getStrokeColor({ success: props.success, strokeColor: props.strokeColor }));
        const wrapperClassName = computed(() => ({
            [`${props.prefixCls}-inner`]: true,
            [`${props.prefixCls}-circle-gradient`]: isGradient.value,
        }));
        return () => {
            var _a;
            return (<div class={wrapperClassName.value} style={circleStyle.value}>
        <VCCircle percent={percent.value} strokeWidth={circleWidth.value} trailWidth={circleWidth.value} strokeColor={strokeColor.value} strokeLinecap={props.strokeLinecap} trailColor={props.trailColor} prefixCls={props.prefixCls} gapDegree={gapDeg.value} gapPosition={gapPos.value}/>
        {(_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots)}
      </div>);
        };
    },
});
