var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { computed, ref, defineComponent } from 'vue';
import VcSlider from '../vc-slider/src/Slider';
import VcRange from '../vc-slider/src/Range';
import VcHandle from '../vc-slider/src/Handle';
import { withInstall } from '../_util/type';
import useConfigInject from '../_util/hooks/useConfigInject';
import SliderTooltip from './SliderTooltip';
import classNames from '../_util/classNames';
import { useInjectFormItemContext } from '../form/FormItemContext';
const defaultTipFormatter = (value) => (typeof value === 'number' ? value.toString() : '');
export const sliderProps = () => ({
    id: String,
    prefixCls: String,
    tooltipPrefixCls: String,
    range: { type: [Boolean, Object], default: undefined },
    reverse: { type: Boolean, default: undefined },
    min: Number,
    max: Number,
    step: { type: [Number, Object] },
    marks: { type: Object },
    dots: { type: Boolean, default: undefined },
    value: { type: [Number, Array] },
    defaultValue: { type: [Number, Array] },
    included: { type: Boolean, default: undefined },
    disabled: { type: Boolean, default: undefined },
    vertical: { type: Boolean, default: undefined },
    tipFormatter: {
        type: [Function, Object],
        default: () => defaultTipFormatter,
    },
    tooltipVisible: { type: Boolean, default: undefined },
    tooltipPlacement: { type: String },
    getTooltipPopupContainer: {
        type: Function,
    },
    autofocus: { type: Boolean, default: undefined },
    handleStyle: { type: [Object, Array] },
    trackStyle: { type: [Object, Array] },
    onChange: { type: Function },
    onAfterChange: { type: Function },
    onFocus: { type: Function },
    onBlur: { type: Function },
    'onUpdate:value': { type: Function },
});
const Slider = defineComponent({
    name: 'ASlider',
    inheritAttrs: false,
    props: sliderProps(),
    // emits: ['update:value', 'change', 'afterChange', 'blur'],
    slots: ['mark'],
    setup(props, { attrs, slots, emit, expose }) {
        const { prefixCls, rootPrefixCls, direction, getPopupContainer, configProvider } = useConfigInject('slider', props);
        const formItemContext = useInjectFormItemContext();
        const sliderRef = ref();
        const visibles = ref({});
        const toggleTooltipVisible = (index, visible) => {
            visibles.value[index] = visible;
        };
        const tooltipPlacement = computed(() => {
            if (props.tooltipPlacement) {
                return props.tooltipPlacement;
            }
            if (!props.vertical) {
                return 'top';
            }
            return direction.value === 'rtl' ? 'left' : 'right';
        });
        const focus = () => {
            var _a;
            (_a = sliderRef.value) === null || _a === void 0 ? void 0 : _a.focus();
        };
        const blur = () => {
            var _a;
            (_a = sliderRef.value) === null || _a === void 0 ? void 0 : _a.blur();
        };
        const handleChange = (val) => {
            emit('update:value', val);
            emit('change', val);
            formItemContext.onFieldChange();
        };
        const handleBlur = (e) => {
            emit('blur', e);
        };
        expose({
            focus,
            blur,
        });
        const handleWithTooltip = (_a) => {
            var { tooltipPrefixCls } = _a, _b = _a.info, { value, dragging, index } = _b, restProps = __rest(_b, ["value", "dragging", "index"]);
            const { tipFormatter, tooltipVisible, getTooltipPopupContainer } = props;
            const isTipFormatter = tipFormatter ? visibles.value[index] || dragging : false;
            const visible = tooltipVisible || (tooltipVisible === undefined && isTipFormatter);
            return (<SliderTooltip prefixCls={tooltipPrefixCls} title={tipFormatter ? tipFormatter(value) : ''} visible={visible} placement={tooltipPlacement.value} transitionName={`${rootPrefixCls.value}-zoom-down`} key={index} overlayClassName={`${prefixCls.value}-tooltip`} getPopupContainer={getTooltipPopupContainer || getPopupContainer.value}>
          <VcHandle {...restProps} value={value} onMouseenter={() => toggleTooltipVisible(index, true)} onMouseleave={() => toggleTooltipVisible(index, false)}/>
        </SliderTooltip>);
        };
        return () => {
            const { tooltipPrefixCls: customizeTooltipPrefixCls, range, id = formItemContext.id.value } = props, restProps = __rest(props, ["tooltipPrefixCls", "range", "id"]);
            const tooltipPrefixCls = configProvider.getPrefixCls('tooltip', customizeTooltipPrefixCls);
            const cls = classNames(attrs.class, {
                [`${prefixCls.value}-rtl`]: direction.value === 'rtl',
            });
            // make reverse default on rtl direction
            if (direction.value === 'rtl' && !restProps.vertical) {
                restProps.reverse = !restProps.reverse;
            }
            // extrack draggableTrack from range={{ ... }}
            let draggableTrack;
            if (typeof range === 'object') {
                draggableTrack = range.draggableTrack;
            }
            if (range) {
                return (<VcRange {...restProps} step={restProps.step} draggableTrack={draggableTrack} class={cls} ref={sliderRef} handle={(info) => handleWithTooltip({
                        tooltipPrefixCls,
                        prefixCls: prefixCls.value,
                        info,
                    })} prefixCls={prefixCls.value} onChange={handleChange} onBlur={handleBlur} v-slots={{ mark: slots.mark }}/>);
            }
            return (<VcSlider {...restProps} id={id} step={restProps.step} class={cls} ref={sliderRef} handle={(info) => handleWithTooltip({
                    tooltipPrefixCls,
                    prefixCls: prefixCls.value,
                    info,
                })} prefixCls={prefixCls.value} onChange={handleChange} onBlur={handleBlur} v-slots={{ mark: slots.mark }}/>);
        };
    },
});
export default withInstall(Slider);
