var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { isPresetColor } from './utils';
import { defineComponent, computed } from 'vue';
import PropTypes from '../_util/vue-types';
import useConfigInject from '../_util/hooks/useConfigInject';
export const ribbonProps = () => ({
    prefix: String,
    color: { type: String },
    text: PropTypes.any,
    placement: { type: String, default: 'end' },
});
export default defineComponent({
    name: 'ABadgeRibbon',
    inheritAttrs: false,
    props: ribbonProps(),
    slots: ['text'],
    setup(props, { attrs, slots }) {
        const { prefixCls, direction } = useConfigInject('ribbon', props);
        const colorInPreset = computed(() => isPresetColor(props.color));
        const ribbonCls = computed(() => [
            prefixCls.value,
            `${prefixCls.value}-placement-${props.placement}`,
            {
                [`${prefixCls.value}-rtl`]: direction.value === 'rtl',
                [`${prefixCls.value}-color-${props.color}`]: colorInPreset.value,
            },
        ]);
        return () => {
            var _a, _b;
            const { class: className, style } = attrs, restAttrs = __rest(attrs, ["class", "style"]);
            const colorStyle = {};
            const cornerColorStyle = {};
            if (props.color && !colorInPreset.value) {
                colorStyle.background = props.color;
                cornerColorStyle.color = props.color;
            }
            return (<div class={`${prefixCls.value}-wrapper`} {...restAttrs}>
          {(_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots)}
          <div class={[ribbonCls.value, className]} style={Object.assign(Object.assign({}, colorStyle), style)}>
            <span class={`${prefixCls.value}-text`}>{props.text || ((_b = slots.text) === null || _b === void 0 ? void 0 : _b.call(slots))}</span>
            <div class={`${prefixCls.value}-corner`} style={cornerColorStyle}/>
          </div>
        </div>);
        };
    },
});
