import { defineComponent } from 'vue';
import classNames from '../_util/classNames';
import PropTypes from '../_util/vue-types';
import initDefaultProps from '../_util/props-util/initDefaultProps';
import { tuple } from '../_util/type';
import useConfigInject from '../_util/hooks/useConfigInject';
export const timelineItemProps = () => ({
    prefixCls: String,
    color: String,
    dot: PropTypes.any,
    pending: { type: Boolean, default: undefined },
    position: PropTypes.oneOf(tuple('left', 'right', '')).def(''),
    label: PropTypes.any,
});
export default defineComponent({
    name: 'ATimelineItem',
    props: initDefaultProps(timelineItemProps(), {
        color: 'blue',
        pending: false,
    }),
    slots: ['dot', 'label'],
    setup(props, { slots }) {
        const { prefixCls } = useConfigInject('timeline', props);
        return () => {
            var _a, _b, _c;
            const { color = '', pending, label = (_a = slots.label) === null || _a === void 0 ? void 0 : _a.call(slots), dot = (_b = slots.dot) === null || _b === void 0 ? void 0 : _b.call(slots) } = props;
            const itemClassName = classNames({
                [`${prefixCls.value}-item`]: true,
                [`${prefixCls.value}-item-pending`]: pending,
            });
            const dotClassName = classNames({
                [`${prefixCls.value}-item-head`]: true,
                [`${prefixCls.value}-item-head-custom`]: dot,
                [`${prefixCls.value}-item-head-${color}`]: true,
            });
            const customColor = /blue|red|green|gray/.test(color || '') ? undefined : color;
            return (<li class={itemClassName}>
          {label && <div class={`${prefixCls.value}-item-label`}>{label}</div>}
          <div class={`${prefixCls.value}-item-tail`}/>
          <div class={dotClassName} style={{ borderColor: customColor, color: customColor }}>
            {dot}
          </div>
          <div class={`${prefixCls.value}-item-content`}>{(_c = slots.default) === null || _c === void 0 ? void 0 : _c.call(slots)}</div>
        </li>);
        };
    },
});
