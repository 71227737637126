import devWarning from '../../vc-util/devWarning';
import { renderSlot } from 'vue';
import { SELECTION_COLUMN } from './useSelection';
import { EXPAND_COLUMN } from '../../vc-table';
function fillSlots(columns, contextSlots) {
    const $slots = contextSlots.value;
    return columns.map(column => {
        var _a;
        if (column === SELECTION_COLUMN || column === EXPAND_COLUMN)
            return column;
        const cloneColumn = Object.assign({}, column);
        const { slots = {} } = cloneColumn;
        cloneColumn.__originColumn__ = column;
        if (process.env.NODE_ENV !== 'production')
            devWarning(!('slots' in cloneColumn), 'Table', '`column.slots` is deprecated. Please use `v-slot:headerCell` `v-slot:bodyCell` instead.');
        Object.keys(slots).forEach(key => {
            const name = slots[key];
            if (cloneColumn[key] === undefined && $slots[name]) {
                cloneColumn[key] = $slots[name];
            }
        });
        if (contextSlots.value.headerCell && !((_a = column.slots) === null || _a === void 0 ? void 0 : _a.title)) {
            cloneColumn.title = renderSlot(contextSlots.value, 'headerCell', {
                title: column.title,
                column,
            }, () => [column.title]);
        }
        if ('children' in cloneColumn) {
            cloneColumn.children = fillSlots(cloneColumn.children, contextSlots);
        }
        return cloneColumn;
    });
}
export default function useColumns(contextSlots) {
    const filledColumns = (columns) => fillSlots(columns, contextSlots);
    return [filledColumns];
}
