import { computed, defineComponent } from 'vue';
import { progressProps } from './props';
export const stepsProps = () => (Object.assign(Object.assign({}, progressProps()), { steps: Number, size: {
        type: String,
    }, strokeColor: String, trailColor: String }));
export default defineComponent({
    name: 'Steps',
    props: stepsProps(),
    setup(props, { slots }) {
        const current = computed(() => Math.round(props.steps * ((props.percent || 0) / 100)));
        const stepWidth = computed(() => (props.size === 'small' ? 2 : 14));
        const styledSteps = computed(() => {
            const { steps, strokeWidth = 8, strokeColor, trailColor, prefixCls } = props;
            const temp = [];
            for (let i = 0; i < steps; i += 1) {
                const cls = {
                    [`${prefixCls}-steps-item`]: true,
                    [`${prefixCls}-steps-item-active`]: i <= current.value - 1,
                };
                temp.push(<div key={i} class={cls} style={{
                        backgroundColor: i <= current.value - 1 ? strokeColor : trailColor,
                        width: `${stepWidth.value}px`,
                        height: `${strokeWidth}px`,
                    }}/>);
            }
            return temp;
        });
        return () => {
            var _a;
            return (<div class={`${props.prefixCls}-steps-outer`}>
        {styledSteps.value}
        {(_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots)}
      </div>);
        };
    },
});
