import devWarning from '../../../vc-util/devWarning';
import useState from '../../../_util/hooks/useState';
import { computed } from 'vue';
import { getColumnPos, renderColumnTitle, getColumnKey } from '../../util';
import FilterDropdown from './FilterDropdown';
function collectFilterStates(columns, init, pos) {
    let filterStates = [];
    (columns || []).forEach((column, index) => {
        var _a, _b;
        const columnPos = getColumnPos(index, pos);
        const hasFilterDropdown = column.filterDropdown || ((_a = column === null || column === void 0 ? void 0 : column.slots) === null || _a === void 0 ? void 0 : _a.filterDropdown) || column.customFilterDropdown;
        if (column.filters || hasFilterDropdown || 'onFilter' in column) {
            if ('filteredValue' in column) {
                // Controlled
                let filteredValues = column.filteredValue;
                if (!hasFilterDropdown) {
                    filteredValues = (_b = filteredValues === null || filteredValues === void 0 ? void 0 : filteredValues.map(String)) !== null && _b !== void 0 ? _b : filteredValues;
                }
                filterStates.push({
                    column,
                    key: getColumnKey(column, columnPos),
                    filteredKeys: filteredValues,
                    forceFiltered: column.filtered,
                });
            }
            else {
                // Uncontrolled
                filterStates.push({
                    column,
                    key: getColumnKey(column, columnPos),
                    filteredKeys: (init && column.defaultFilteredValue
                        ? column.defaultFilteredValue
                        : undefined),
                    forceFiltered: column.filtered,
                });
            }
        }
        if ('children' in column) {
            filterStates = [...filterStates, ...collectFilterStates(column.children, init, columnPos)];
        }
    });
    return filterStates;
}
function injectFilter(prefixCls, dropdownPrefixCls, columns, filterStates, triggerFilter, getPopupContainer, locale, pos) {
    return columns.map((column, index) => {
        var _a;
        const columnPos = getColumnPos(index, pos);
        const { filterMultiple = true, filterMode, filterSearch } = column;
        let newColumn = column;
        const hasFilterDropdown = column.filterDropdown || ((_a = column === null || column === void 0 ? void 0 : column.slots) === null || _a === void 0 ? void 0 : _a.filterDropdown) || column.customFilterDropdown;
        if (newColumn.filters || hasFilterDropdown) {
            const columnKey = getColumnKey(newColumn, columnPos);
            const filterState = filterStates.find(({ key }) => columnKey === key);
            newColumn = Object.assign(Object.assign({}, newColumn), { title: (renderProps) => (<FilterDropdown tablePrefixCls={prefixCls} prefixCls={`${prefixCls}-filter`} dropdownPrefixCls={dropdownPrefixCls} column={newColumn} columnKey={columnKey} filterState={filterState} filterMultiple={filterMultiple} filterMode={filterMode} filterSearch={filterSearch} triggerFilter={triggerFilter} locale={locale} getPopupContainer={getPopupContainer}>
            {renderColumnTitle(column.title, renderProps)}
          </FilterDropdown>) });
        }
        if ('children' in newColumn) {
            newColumn = Object.assign(Object.assign({}, newColumn), { children: injectFilter(prefixCls, dropdownPrefixCls, newColumn.children, filterStates, triggerFilter, getPopupContainer, locale, columnPos) });
        }
        return newColumn;
    });
}
export function flattenKeys(filters) {
    let keys = [];
    (filters || []).forEach(({ value, children }) => {
        keys.push(value);
        if (children) {
            keys = [...keys, ...flattenKeys(children)];
        }
    });
    return keys;
}
function generateFilterInfo(filterStates) {
    const currentFilters = {};
    filterStates.forEach(({ key, filteredKeys, column }) => {
        var _a;
        const hasFilterDropdown = column.filterDropdown || ((_a = column === null || column === void 0 ? void 0 : column.slots) === null || _a === void 0 ? void 0 : _a.filterDropdown) || column.customFilterDropdown;
        const { filters } = column;
        if (hasFilterDropdown) {
            currentFilters[key] = filteredKeys || null;
        }
        else if (Array.isArray(filteredKeys)) {
            const keys = flattenKeys(filters);
            currentFilters[key] = keys.filter(originKey => filteredKeys.includes(String(originKey)));
        }
        else {
            currentFilters[key] = null;
        }
    });
    return currentFilters;
}
export function getFilterData(data, filterStates) {
    return filterStates.reduce((currentData, filterState) => {
        const { column: { onFilter, filters }, filteredKeys, } = filterState;
        if (onFilter && filteredKeys && filteredKeys.length) {
            return currentData.filter(record => filteredKeys.some(key => {
                const keys = flattenKeys(filters);
                const keyIndex = keys.findIndex(k => String(k) === String(key));
                const realKey = keyIndex !== -1 ? keys[keyIndex] : key;
                return onFilter(realKey, record);
            }));
        }
        return currentData;
    }, data);
}
function useFilter({ prefixCls, dropdownPrefixCls, mergedColumns, locale, onFilterChange, getPopupContainer, }) {
    const [filterStates, setFilterStates] = useState(collectFilterStates(mergedColumns.value, true));
    const mergedFilterStates = computed(() => {
        const collectedStates = collectFilterStates(mergedColumns.value, false);
        const filteredKeysIsNotControlled = collectedStates.every(({ filteredKeys }) => filteredKeys === undefined);
        // Return if not controlled
        if (filteredKeysIsNotControlled) {
            return filterStates.value;
        }
        const filteredKeysIsAllControlled = collectedStates.every(({ filteredKeys }) => filteredKeys !== undefined);
        if (process.env.NODE_ENV !== 'production')
            devWarning(filteredKeysIsNotControlled || filteredKeysIsAllControlled, 'Table', '`FilteredKeys` should all be controlled or not controlled.');
        return collectedStates;
    });
    const filters = computed(() => generateFilterInfo(mergedFilterStates.value));
    const triggerFilter = (filterState) => {
        const newFilterStates = mergedFilterStates.value.filter(({ key }) => key !== filterState.key);
        newFilterStates.push(filterState);
        setFilterStates(newFilterStates);
        onFilterChange(generateFilterInfo(newFilterStates), newFilterStates);
    };
    const transformColumns = (innerColumns) => {
        return injectFilter(prefixCls.value, dropdownPrefixCls.value, innerColumns, mergedFilterStates.value, triggerFilter, getPopupContainer.value, locale.value);
    };
    return [transformColumns, mergedFilterStates, filters];
}
export default useFilter;
