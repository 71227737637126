import initDefaultProps from '../_util/props-util/initDefaultProps';
import SearchOutlined from '@ant-design/icons-vue/SearchOutlined';
import Input from '../input';
import { defineComponent } from 'vue';
export const transferSearchProps = {
    prefixCls: String,
    placeholder: String,
    value: String,
    handleClear: Function,
    disabled: { type: Boolean, default: undefined },
    onChange: Function,
};
export default defineComponent({
    name: 'Search',
    inheritAttrs: false,
    props: initDefaultProps(transferSearchProps, {
        placeholder: '',
    }),
    emits: ['change'],
    setup(props, { emit }) {
        const handleChange = (e) => {
            var _a;
            emit('change', e);
            if (e.target.value === '') {
                (_a = props.handleClear) === null || _a === void 0 ? void 0 : _a.call(props);
            }
        };
        return () => {
            const { placeholder, value, prefixCls, disabled } = props;
            return (<Input placeholder={placeholder} class={prefixCls} value={value} onChange={handleChange} disabled={disabled} allowClear v-slots={{ prefix: () => <SearchOutlined /> }}/>);
        };
    },
});
