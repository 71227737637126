var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import PropTypes from '../_util/vue-types';
import { filterEmpty } from '../_util/props-util';
import { cloneElement } from '../_util/vnode';
import { defineComponent } from 'vue';
import classNames from '../_util/classNames';
export default defineComponent({
    name: 'Steps',
    props: {
        type: PropTypes.string.def('default'),
        prefixCls: PropTypes.string.def('vc-steps'),
        iconPrefix: PropTypes.string.def('vc'),
        direction: PropTypes.string.def('horizontal'),
        labelPlacement: PropTypes.string.def('horizontal'),
        status: PropTypes.string.def('process'),
        size: PropTypes.string.def(''),
        progressDot: PropTypes.oneOfType([PropTypes.looseBool, PropTypes.func]).def(undefined),
        initial: PropTypes.number.def(0),
        current: PropTypes.number.def(0),
        icons: PropTypes.shape({
            finish: PropTypes.any,
            error: PropTypes.any,
        }).loose,
        stepIcon: Function,
    },
    slots: ['stepIcon', 'progressDot'],
    emits: ['change'],
    setup(props, { slots, emit }) {
        const onStepClick = next => {
            const { current } = props;
            if (current !== next) {
                emit('change', next);
            }
        };
        return () => {
            var _a;
            const { prefixCls, direction, type, labelPlacement, iconPrefix, status, size, current, progressDot = slots.progressDot, initial, icons, stepIcon = slots.stepIcon, } = props;
            const isNav = type === 'navigation';
            const adjustedLabelPlacement = progressDot ? 'vertical' : labelPlacement;
            const classString = classNames(prefixCls, `${prefixCls}-${direction}`, {
                [`${prefixCls}-${size}`]: size,
                [`${prefixCls}-label-${adjustedLabelPlacement}`]: direction === 'horizontal',
                [`${prefixCls}-dot`]: !!progressDot,
                [`${prefixCls}-navigation`]: isNav,
            });
            const children = filterEmpty((_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots));
            return (<div class={classString}>
          {children.map((child, index) => {
                    // description: PropTypes.any,
                    // icon: PropTypes.any,
                    // status: PropTypes.oneOf(tuple('wait', 'process', 'finish', 'error')),
                    // disabled: { type: Boolean, default: undefined },
                    // title: PropTypes.any,
                    // subTitle: PropTypes.any,
                    const _a = child.props || {}, { prefixCls: pre = prefixCls } = _a, restProps = __rest(_a, ["prefixCls"]);
                    const stepNumber = initial + index;
                    const stepProps = Object.assign(Object.assign({}, restProps), { stepNumber: stepNumber + 1, stepIndex: stepNumber, key: stepNumber, prefixCls: pre, iconPrefix,
                        progressDot,
                        icons,
                        stepIcon,
                        onStepClick });
                    // fix tail color
                    if (status === 'error' && index === current - 1) {
                        stepProps.class = `${prefixCls}-next-error`;
                    }
                    if (!restProps.status) {
                        if (stepNumber === current) {
                            stepProps.status = status;
                        }
                        else if (stepNumber < current) {
                            stepProps.status = 'finish';
                        }
                        else {
                            stepProps.status = 'wait';
                        }
                    }
                    stepProps.active = stepNumber === current;
                    return cloneElement(child, stepProps);
                })}
        </div>);
        };
    },
});
