import { computed, defineComponent, ref, watch } from 'vue';
import Transition from '../../_util/transition';
import { useInjectMenu, MenuContextProvider } from './hooks/useMenuContext';
import SubMenuList from './SubMenuList';
export default defineComponent({
    name: 'InlineSubMenuList',
    inheritAttrs: false,
    props: {
        id: String,
        open: Boolean,
        keyPath: Array,
    },
    setup(props, { slots }) {
        const fixedMode = computed(() => 'inline');
        const { motion, mode, defaultMotions } = useInjectMenu();
        const sameModeRef = computed(() => mode.value === fixedMode.value);
        const destroy = ref(!sameModeRef.value);
        const mergedOpen = computed(() => (sameModeRef.value ? props.open : false));
        // ================================= Effect =================================
        // Reset destroy state when mode change back
        watch(mode, () => {
            if (sameModeRef.value) {
                destroy.value = false;
            }
        }, { flush: 'post' });
        const mergedMotion = computed(() => {
            var _a, _b;
            const m = motion.value || ((_a = defaultMotions.value) === null || _a === void 0 ? void 0 : _a[fixedMode.value]) || ((_b = defaultMotions.value) === null || _b === void 0 ? void 0 : _b.other);
            const res = typeof m === 'function' ? m() : m;
            return Object.assign(Object.assign({}, res), { appear: props.keyPath.length <= 1 });
        });
        return () => {
            var _a;
            if (destroy.value) {
                return null;
            }
            return (<MenuContextProvider mode={fixedMode.value}>
          <Transition {...mergedMotion.value}>
            <SubMenuList v-show={mergedOpen.value} id={props.id}>
              {(_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots)}
            </SubMenuList>
          </Transition>
        </MenuContextProvider>);
        };
    },
});
