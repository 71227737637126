var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from '../_util/classNames';
import LocaleReceiver from '../locale-provider/LocaleReceiver';
import DefaultEmptyImg from './empty';
import SimpleEmptyImg from './simple';
import { filterEmpty } from '../_util/props-util';
import PropTypes from '../_util/vue-types';
import { withInstall } from '../_util/type';
import useConfigInject from '../_util/hooks/useConfigInject';
const defaultEmptyImg = <DefaultEmptyImg />;
const simpleEmptyImg = <SimpleEmptyImg />;
const Empty = (props, { slots = {}, attrs }) => {
    var _a;
    const { direction, prefixCls: prefixClsRef } = useConfigInject('empty', props);
    const prefixCls = prefixClsRef.value;
    const _b = Object.assign(Object.assign({}, props), attrs), { image = defaultEmptyImg, description = ((_a = slots.description) === null || _a === void 0 ? void 0 : _a.call(slots)) || undefined, imageStyle, class: className = '' } = _b, restProps = __rest(_b, ["image", "description", "imageStyle", "class"]);
    return (<LocaleReceiver componentName="Empty" children={(locale) => {
            const des = typeof description !== 'undefined' ? description : locale.description;
            const alt = typeof des === 'string' ? des : 'empty';
            let imageNode = null;
            if (typeof image === 'string') {
                imageNode = <img alt={alt} src={image}/>;
            }
            else {
                imageNode = image;
            }
            return (<div class={classNames(prefixCls, className, {
                    [`${prefixCls}-normal`]: image === simpleEmptyImg,
                    [`${prefixCls}-rtl`]: direction.value === 'rtl',
                })} {...restProps}>
            <div class={`${prefixCls}-image`} style={imageStyle}>
              {imageNode}
            </div>
            {des && <p class={`${prefixCls}-description`}>{des}</p>}
            {slots.default && (<div class={`${prefixCls}-footer`}>{filterEmpty(slots.default())}</div>)}
          </div>);
        }}/>);
};
Empty.displayName = 'AEmpty';
Empty.PRESENTED_IMAGE_DEFAULT = defaultEmptyImg;
Empty.PRESENTED_IMAGE_SIMPLE = simpleEmptyImg;
Empty.inheritAttrs = false;
Empty.props = {
    prefixCls: String,
    image: PropTypes.any,
    description: PropTypes.any,
    imageStyle: { type: Object, default: undefined },
};
export default withInstall(Empty);
