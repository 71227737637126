import { computed, defineComponent } from 'vue';
import classNames from '../_util/classNames';
import useConfigInject from '../_util/hooks/useConfigInject';
import Element, { skeletonElementProps } from './Element';
import omit from '../_util/omit';
const SkeletonInput = defineComponent({
    name: 'ASkeletonInput',
    props: Object.assign(Object.assign({}, omit(skeletonElementProps(), ['shape'])), { size: String }),
    setup(props) {
        const { prefixCls } = useConfigInject('skeleton', props);
        const cls = computed(() => classNames(prefixCls.value, `${prefixCls.value}-element`, {
            [`${prefixCls.value}-active`]: props.active,
        }));
        return () => {
            return (<div class={cls.value}>
          <Element {...props} prefixCls={`${prefixCls.value}-input`}/>
        </div>);
        };
    },
});
export default SkeletonInput;
