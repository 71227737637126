var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import omit from '../_util/omit';
import { tupleNum } from '../_util/type';
import warning from '../_util/warning';
import Base, { baseProps } from './Base';
const TITLE_ELE_LIST = tupleNum(1, 2, 3, 4, 5);
export const titleProps = () => (Object.assign(Object.assign({}, omit(baseProps(), ['component', 'strong'])), { level: Number }));
const Title = (props, { slots, attrs }) => {
    const { level = 1 } = props, restProps = __rest(props, ["level"]);
    let component;
    if (TITLE_ELE_LIST.indexOf(level) !== -1) {
        component = `h${level}`;
    }
    else {
        warning(false, 'Typography', 'Title only accept `1 | 2 | 3 | 4 | 5` as `level` value.');
        component = 'h1';
    }
    const titleProps = Object.assign(Object.assign(Object.assign({}, restProps), { component }), attrs);
    return <Base {...titleProps} v-slots={slots}></Base>;
};
Title.displayName = 'ATypographyTitle';
Title.inheritAttrs = false;
Title.props = titleProps();
export default Title;
